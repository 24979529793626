<template>
  <div class="page-review">
    <el-row class="review-count" :gutter="20" style="margin-bottom: 40px">
      <el-col :span="6">
        <el-card>
          <div
            class="review-count-block"
            :class="{ 'review-count-block-current': expertSearchType === 0 }"
          >
            <div class="review-count-block-main">
              <p>待评审作品</p>
              <p>
                <em>{{ lastCount }}</em>
                <em>/</em>
                <em style="font-size: 20px">{{ totalCount }}</em>
              </p>
            </div>
            <div class="review-count-block-action">
              <el-button
                v-if="expertSearchType !== 0"
                type="primary"
                @click="expertSearchTypeChange(0)"
              >
                查看
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="expertSearchTypeChange(undefined)"
              >
                取消选择
              </el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div
            class="review-count-block"
            :class="{ 'review-count-block-current': expertSearchType === 1 }"
          >
            <div class="review-count-block-main">
              <p>入围作品</p>
              <p>
                <em style="color: green">{{ passCount }}</em>
              </p>
            </div>
            <div class="review-count-block-action">
              <el-button
                v-if="expertSearchType !== 1"
                type="primary"
                @click="expertSearchTypeChange(1)"
              >
                查看
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="expertSearchTypeChange(undefined)"
              >
                取消选择
              </el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div
            class="review-count-block"
            :class="{ 'review-count-block-current': expertSearchType === 2 }"
          >
            <div class="review-count-block-main">
              <p>未入围作品</p>
              <p>
                <em style="color: red">
                  {{ totalCount - lastCount - passCount - undeterminedCount }}
                </em>
              </p>
            </div>
            <div class="review-count-block-action">
              <el-button
                v-if="expertSearchType !== 2"
                type="primary"
                @click="expertSearchTypeChange(2)"
              >
                查看
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="expertSearchTypeChange(undefined)"
              >
                取消选择
              </el-button>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div
            class="review-count-block"
            :class="{ 'review-count-block-current': expertSearchType === 3 }"
          >
            <div class="review-count-block-main">
              <p>待定作品</p>
              <p>
                <em style="color: yellowgreen">{{ undeterminedCount }}</em>
              </p>
            </div>
            <div class="review-count-block-action">
              <el-button
                v-if="expertSearchType !== 3"
                type="primary"
                @click="expertSearchTypeChange(3)"
              >
                查看
              </el-button>
              <el-button
                v-else
                type="primary"
                @click="expertSearchTypeChange(undefined)"
              >
                取消选择
              </el-button>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <list-page
      ref="listPageRef"
      inline
      label-width="80px"
      :model="model"
      :fields="fields"
      :columns="columns"
      :fetch="fetch"
      :headerCellStyle="{ background: '#f9fafc' }"
      :submitter="{
        props: {
          style: {
            display: 'flex',
            justifyContent: 'flex-end'
          }
        },
        submitText: '搜索',
        resetText: '重置'
      }"
    >
      <template #addon>
        <div style="margin-bottom: 16px">
          <el-button
            type="primary"
            @click="downloadWorks"
            :loading="downloadLoading"
          >
            导出通过作品
          </el-button>
        </div>
      </template>
    </list-page>
    <el-dialog
      :title="current && current.name + ' 评审'"
      :visible.sync="scoreVisible"
      width="540px"
    >
      <pro-form
        label-width="100px"
        label-suffix=":"
        :model="scoreModel"
        :fields="scoreFields"
        :rules="scoreRules"
        :submitter="{
          submitButtonProps: {
            scoreLoading
          },
          submitText: '评审',
          resetText: '取消'
        }"
        @submit="scoreSubmit"
        @reset="scoreReset"
      ></pro-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as ConstantRouter from '@/constant/router'
import { localStore } from '@/utils/store'
import { exportFile } from '@/utils/tools'

export default {
  data() {
    return {
      jsId: this.$route.params.jsId,
      totalCount: 0,
      lastCount: 0,
      passCount: 0,
      scoreCount: 0,
      undeterminedCount: 0,
      downloadLoading: false,
      model: {
        name: ''
      },
      expertSearchType: undefined,
      scoreVisible: false,
      scoreModel: {
        firstScore: undefined,
        score: '',
        remark: ''
      },
      scoreRules: {
        score: [{ required: true, message: '请输入分数', trigger: 'change' }],
        firstScore: [
          { required: true, message: '请选择是否通过', trigger: 'change' }
        ]
      },
      scoreLoading: false,
      current: null,
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '作品名称',
            prop: 'name'
          }
        }
      ],
      columns: [
        {
          label: '作品名称',
          prop: 'name',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '缩略图',
          prop: 'thumbnail',
          width: '120px',
          render(_, val) {
            if (!val) return '-'
            return (
              <el-image
                style="width: 100px; height: 100px"
                src={val}
                preview-src-list={[val]}
              ></el-image>
            )
          }
        },
        {
          label: '作品简介',
          prop: 'title',
          render(_, text) {
            if (text) {
              return <p title={text}>{text.slice(0, 10) + '...'}</p>
            }
            return '-'
          }
        },
        // {
        //   label: '作品版面',
        //   prop: 'faceImg',
        //   render(_, val) {
        //     if (!val) return '-'
        //     return (
        //       <el-image
        //         style="width: 100px; height: 100px"
        //         src={val}
        //         preview-src-list={[val]}
        //       ></el-image>
        //     )
        //   }
        // },
        // {
        //   label: '补充细节图1',
        //   prop: 'detailImg',
        //   render(_, val) {
        //     if (!val) return '-'
        //     return (
        //       <el-image
        //         style="width: 100px; height: 100px"
        //         src={val}
        //         preview-src-list={[val]}
        //       ></el-image>
        //     )
        //   }
        // },
        // {
        //   label: '补充细节图2',
        //   prop: 'appendFile1',
        //   render(_, val) {
        //     if (!val) return '-'
        //     return (
        //       <el-image
        //         style="width: 100px; height: 100px"
        //         src={val}
        //         preview-src-list={[val]}
        //       ></el-image>
        //     )
        //   }
        // },
        // {
        //   label: '补充细节图3',
        //   prop: 'appendFile2',
        //   render(_, val) {
        //     if (!val) return '-'
        //     return (
        //       <el-image
        //         style="width: 100px; height: 100px"
        //         src={val}
        //         preview-src-list={[val]}
        //       ></el-image>
        //     )
        //   }
        // },
        {
          label: '是否通过',
          prop: 'first_score',
          render(_, text) {
            if (text === 1) {
              return '通过'
            }
            if (text === 2) {
              return '不通过'
            }
            if (text === 3) {
              return '待定'
            }
            return '待审核'
          }
        },
        // {
        //   label: '分数',
        //   prop: 'score',
        //   render(_, text) {
        //     return text || '-'
        //   }
        // },
        {
          label: '备注',
          prop: 'remark',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '操作',
          width: '120px',
          render: (h, record) => {
            return (
              <div>
                <el-button type="text" onClick={() => this.toDetail(record)}>
                  详情
                </el-button>
                <el-button
                  type="text"
                  onClick={() => {
                    this.scoreVisible = true
                    this.current = record
                    this.scoreModel = {
                      firstScore: this.current.first_score,
                      score: this.current.score,
                      remark: this.current.remark
                    }
                  }}
                >
                  评审
                </el-button>
              </div>
            )
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      judgeInfo(state) {
        return state.judgeInfo
      },
      scoreFields() {
        return [
          ...(this.judgeInfo?.type === 1
            ? [
                {
                  type: 'radio-group',
                  formItemProps: {
                    label: '初审',
                    prop: 'firstScore'
                  },
                  children: [
                    {
                      label: 1,
                      text: '通过'
                    },
                    {
                      label: 2,
                      text: '不通过'
                    },
                    {
                      label: 3,
                      text: '待定(加入比较)'
                    }
                  ]
                },
                {
                  type: 'textarea',
                  formItemProps: {
                    label: '备注',
                    prop: 'remark'
                  },
                  style: {
                    width: '360px'
                  }
                }
              ]
            : []),
          ...(this.judgeInfo?.type === 2
            ? [
                {
                  type: 'input',
                  formItemProps: {
                    label: '分数',
                    prop: 'score'
                  },
                  style: {
                    width: '360px'
                  }
                },
                {
                  type: 'textarea',
                  formItemProps: {
                    label: '备注',
                    prop: 'remark'
                  },
                  style: {
                    width: '360px'
                  }
                }
              ]
            : [])
        ]
      }
    })
  },
  watch: {
    '$route.query.track': {
      handler(val) {
        if (val) {
          this.fetchTotal()
          this.$refs.listPageRef?.fetchData({ currentPage: 1 })
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchTotal() {
      if (this.judgeInfo?.type === 1) {
        // 初审
        this.getOpusScoreCountFirst()
      }
      if (this.judgeInfo?.type === 2) {
        // 复审
        this.getOpusScoreCount()
      }
    },
    expertSearchTypeChange(expertSearchType) {
      this.expertSearchType = expertSearchType
      this.$refs.listPageRef.fetchData({
        pageIndex: 1,
        expertSearchType
      })
    },
    async getOpusScoreCount() {
      const [err, res] = await this.$serve.jsOpus.getOpusScoreCount({
        data: {
          track: this.$route.query.track
        }
      })
      if (err) return
      this.totalCount = res.totalCount
      this.lastCount = res.lastCount
      this.scoreCount = res.scoreCount
      this.passCount = res.passCount
      this.undeterminedCount = res.undeterminedCount
    },
    async getOpusScoreCountFirst() {
      const [err, res] = await this.$serve.jsOpus.getOpusScoreCountFirst({
        data: {
          track: this.$route.query.track
        }
      })
      if (err) return
      this.totalCount = res.totalCount
      this.lastCount = res.lastCount
      this.scoreCount = res.scoreCount
      this.passCount = res.passCount
      this.undeterminedCount = res.undeterminedCount
    },
    fetch({ currentPage, pageSize, ...req }) {
      if (!this.$route.query.track)
        return Promise.resolve({
          list: []
        })
      return this.$serve.jsOpus
        .getPageList({
          data: {
            jsId: this.jsId,
            pageIndex: currentPage,
            pageSize: pageSize,
            type: this.judgeInfo?.type,
            track: this.$route.query.track,
            expertSearchType: this.expertSearchType,
            ...req
          }
        })
        .then(([err, data]) => {
          if (err) {
            return {
              list: []
            }
          }
          const { records, ...res } = data
          return {
            ...res,
            list: records
          }
        })
    },
    toDetail(record) {
      this.$router.push({
        name: ConstantRouter.JUDGE_REVIEW_DETAIL,
        params: {
          id: record.id
        },
        query: {
          track: this.$route.query.track
        }
      })
    },
    async scoreSubmit() {
      if (this.judgeInfo.type === 1) {
        this.addFirst()
        // 初审
        return
      }
      if (this.judgeInfo.type === 2) {
        // 复审
        if (this.current.score) {
          this.scoreUpdate()
        } else {
          this.scoreAdd()
        }
      }
    },
    async addFirst() {
      this.scoreLoading = true
      const [err] = await this.$serve.jsOpusScore.addFirst({
        data: {
          id: this.current.id,
          opusId: this.current.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          firstScore: this.scoreModel.firstScore,
          remark: this.scoreModel.remark
        }
      })
      this.scoreLoading = false
      if (err) return
      this.$message.success('初审成功')
      this.scoreVisible = false
      this.scoreVisible = false
      this.$refs.listPageRef.fetchData()
      this.fetchTotal()
    },
    async scoreAdd() {
      this.scoreLoading = true
      const [err] = await this.$serve.jsOpusScore.add({
        data: {
          id: this.current.id,
          opusId: this.current.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          score: this.scoreModel.score,
          remark: this.scoreModel.remark
        }
      })
      this.scoreLoading = false
      if (err) return
      this.$message.success('打分成功')
      this.getOpusScoreCount()
      this.scoreVisible = false
      this.$refs.listPageRef.fetchData()
      this.fetchTotal()
    },
    async scoreUpdate() {
      this.scoreLoading = true
      const [err] = await this.$serve.jsOpusScore.update({
        data: {
          id: this.current.id,
          opusId: this.current.id,
          expertId: localStore.get('judge-id'),
          jsId: this.jsId,
          score: this.scoreModel.score,
          remark: this.scoreModel.remark
        }
      })
      this.scoreLoading = false
      if (err) return
      this.$message.success('修改打分成功')
      this.getOpusScoreCount()
      this.scoreVisible = false
      this.$refs.listPageRef.fetchData()
      this.fetchTotal()
    },
    scoreReset() {
      this.scoreVisible = false
    },
    async downloadWorks() {
      this.downloadLoading = true
      const [err, res] = await this.$serve.jsExpert.exportScoreResult({
        data: {
          jsId: this.jsId,
          track: this.$route.query.track
        }
      })
      this.downloadLoading = false
      if (err === this.$serve.FAIL) return
      exportFile(res.file, res.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.review-count {
  p + p {
    margin-top: 20px;
    // text-align: center;
  }
  em {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }
}

.review-count-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review-count-block-current {
  position: relative;
  &::before {
    content: '已选择';
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 12px;
    background: red;
    padding: 2px 8px;
    border-radius: 0 0 0 8px;
    color: #fff;
  }
}
</style>
